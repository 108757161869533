<!-- 充电桩管理 -->

<template>
  <div class="electric main-cnt">
    <div class="content">
      <common-table ref="chargeTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
        :apiName="PowerStations.getEquipmentLists" :columns="tableColumns" @onDetail="onDetail">
      </common-table>
    </div>

    <!-- 详情 -->
    <w-dialog ref="detailsDialog" class="add-dialog" title="充电枪详情" width="60%" btnWidth="140px" top="20vh"
      :hideFooter="true">
      <common-table ref="detailsTable" tableHeight="500" :ispaging="false" :tableData="connectorInfo"
        :columns="detailsColumns">
      </common-table>
    </w-dialog>
  </div>
</template>

<script setup>
  import { ref, onMounted, nextTick, } from "vue";
  import { PowerStations } from "@/plugins/api.js";

  /** 表格对象 */
  const chargeTable = ref(null);
  const detailsDialog = ref(null);  // 详情弹框对象
  const detailsTable = ref(null);  // 详情表格对象
  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "e_name",
      label: "充电桩名称",
      color: "--text-color",
    },
    {
      prop: "s_name",
      label: "归属电站",
      color: "--text-color",
    },
    {
      prop: "e_no",
      label: "充电桩编号",
      color: "--text-color",
    },
    {
      prop: "equipment_metre_type",
      label: "充电桩类型",
      color: "--text-color",
    },
    {
      prop: "et_name_alias",
      label: "交直流类型",
      color: "--text-color",
    },
    {
      prop: "e_power",
      label: "额定功率(Kw)",
      color: "--text-color",
    },
    {
      type: "block",
      prop: "connector_count",
      label: "充电枪数量",
      active: "onDetail",
      minWidth: 100,
      token: "n_3K1HUxZInHvUCNCnLFmlC1PKdl5g",
    },
  ]);
  const detailsColumns = ref([  // 详情表格配置
   {
     prop: "c_id",
     label: "枪号",
     color: "--text-color",
   },
    {
      prop: "c_name",
      label: "枪名称",
      color: "--text-color",
    },
	{
	  prop: "c_no",
	  label: "编号",
	  color: "--text-color",
	},
   
    {
      prop: "c_voltage_upper_limits",
      label: "额定电压上限(V)",
      color: "--text-color",
    },
    {
      prop: "c_voltage_lower_limits",
      label: "额定电压下限(V)",
      color: "--text-color",
    },
    {
      prop: "c_current",
      label: "额定电流(A)",
      color: "--text-color",
    },
    {
      prop: "c_power",
      label: "额定功率(Kw)",
      color: "--text-color",
    },
    {
      prop: "c_national_standard",
      label: "国家标准",
      color: "--text-color",
    },
  ]);
  /**
   * 
   * 点击数量查看详情
   * 
   * **/
   const connectorInfo = ref([])
  const onDetail = (row) => {
	connectorInfo.value = row.connector_info
    detailsDialog.value.show();
    nextTick(() => {
      detailsTable.value.tableLoad();
    })
  }

  onMounted(() => {
    chargeTable.value.tableLoad();
  });
</script>

<style lang="scss">
  .electric {
    font-family: "Source Han Sans CN";

    .content {
      padding: 20px;
    }
  }
</style>